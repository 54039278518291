import { Link } from "gatsby"
import React from "react"
import Logo from "../images/Tata_Studi_Open_logo.svg"
import { navigate } from "gatsby"
import "hamburgers/_sass/hamburgers/hamburgers.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import PropTypes from "prop-types"

import Blue from "../images/new-blue.svg"
import Yellow from "../images/new-yellow.svg"

class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      indexPath: true,
    }
  }
  infoPopupClose() {
    // document.getElementById("infoAlert").style.display = "none"
    document.getElementById("wrap-banner").style.marginTop = "0"
  }
  componentDidMount() {
    document
      .getElementById("navbar-brand")
      .addEventListener("click", function (e) {
        e.preventDefault()
        navigate("/")
      })

    document.getElementById("hamburger").addEventListener("click", function () {
      if (
        document.getElementById("hamburger").classList.contains("is-active")
      ) {
        document.getElementById("hamburger").classList.remove("is-active")
      } else {
        document.getElementById("hamburger").classList.add("is-active")
      }
    })

    document
      .getElementsByClassName("dropdown-item")[0]
      .addEventListener("click", function () {
        if (
          document.getElementById("hamburger").classList.contains("is-active")
        ) {
          document.getElementById("hamburger").classList.remove("is-active")
        }
        document.getElementById("hamburger").classList.add("collapsed")
        document.getElementById("navbarCollapse").classList.remove("show")
      })

    if (window.location.pathname == "/") {
      this.setState({ indexPath: true })
    } else {
      this.setState({ indexPath: false })
    }
  }
  propTypes = {
    siteTitle: PropTypes.string.isRequired,
  }

  render() {
    const navbarStyle = {
      padding: "0px",
    }
    const navbarNavStyle = {
      width: "150px",
      position: "relative",
    }
    const navContainer = {
      maxWidth: "100%",
    }
    return (
      <div>
        {/* when removing this alert, remove fixed-top classname from this div and move it into navbar in both mobile and desktop headers */}
        <div className="fixed-top">
          {this.state.indexPath ? (
            <div
              className="alert alert-light bg-white alert-dismissible fade show text-center"
              role="alert"
              id="infoAlert"
              style={{ display: "block", marginBottom: 0 }}
            >
              <div
                className="font-weight-bold color-black"
                style={{ fontSize: "11px" }}
              >
                <img src={Blue} height="12px" width="5%" className="new-img" />
                Tata Studi is available for <b>CBSE</b> 1st-10th & 11th-12th
                (Science Stream) | <b>ICSE</b> 1st-5th
                {/* Now Tata Studi is available for <b>ICSE</b> Grade (1-5) along with <span style={{ fontSize: "10px" }}><b>UP, MP & Rajasthan Boards</b></span> for
              class 9th & 10th (for English medium) */}
                <img
                  src={Yellow}
                  height="12px"
                  width="5%"
                  className="new-img"
                />
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={this.infoPopupClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          ) : (
            <> </>
          )}

          <nav
            className="navbar navbar-light navbar-expand-xl"
            style={navbarStyle}
          >
            <div className="container" style={navContainer}>
              <div className="container-fluid bg-white">
                <a className="navbar-brand" href="/" id="navbar-brand">
                  <img src={Logo} alt="logo" className="logo" title="logo" />{" "}
                  {this.props.siteTitle}
                </a>
                <a
                  href="https://learn.tatastudi.com/"
                  className="mobile_login_button"
                >
                  <button
                    className="btn btn-primary login_button"
                    id="nav_login_btn"
                  >
                    Login
                  </button>
                </a>
                <div
                  id="hamburger"
                  className="hamburger hamburger--collapse"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarCollapse"
                  aria-controls="navbarCollapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <div className="hamburger-box">
                    <div className="hamburger-inner" />
                  </div>
                </div>
              </div>

              <div
                className="collapse navbar-collapse nav-mobile"
                id="navbarCollapse"
              >
                <div className="d-flex flex-row-reverse">
                  <div>
                    <ul
                      className="navbar-nav bg-white shadow"
                      style={{ ...navbarNavStyle, zIndex: "1" }}
                    >
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle d-flex flex-row header_item"
                          href="/"
                          data-toggle="collapse"
                          data-target="#aboutstudiDropdown"
                          aria-controls="aboutstudiDropdown"
                        >
                          <i className="fa fa-angle-left" />
                          <span>About Studi</span>
                        </a>
                      </li>

                      <li className="nav-item">
                        <Link
                          className="nav-link header_item"
                          to="/plansandpricing/"
                        >
                          Plans & Pricing
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle d-flex flex-row header_item"
                          href="/"
                          data-toggle="collapse"
                          data-target="#resourcesDropdown"
                          aria-controls="resourcesDropdown"
                        >
                          <i className="fa fa-angle-left" />
                          <span>Resources</span>
                        </a>
                      </li>

                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle d-flex flex-row"
                          href="/support/"
                          data-toggle="collapse"
                          data-target="#supportDropdown"
                          aria-controls="supportDropdown"
                        >
                          <i className="fa fa-angle-left" />
                          <span>Support</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link header_item"
                          to="/explorestudi/"
                        >
                          Explore Studi
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link header_item" to="/studilive/">
                          StudiLive{" "}
                          <img
                            src={Yellow}
                            height="20px"
                            width="20%"
                            className="new-img"
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="accordion" id="accordionDrop">
                    <div
                      className="shadow collapse navbar-collapse bg-white mobile-nav-dropdown"
                      id="aboutstudiDropdown"
                      aria-labelledby="aboutstudiDropdown"
                      data-parent="#accordionDrop"
                    >
                      <AnchorLink
                        className="dropdown-item header_item"
                        to="/#why_studi"
                      >
                        Why Studi?
                      </AnchorLink>
                      <AnchorLink
                        className="dropdown-item header_item"
                        to="/#how_it_works"
                      >
                        How It Works
                      </AnchorLink>
                      <Link
                        className="dropdown-item header_item"
                        to="/aboutus/"
                      >
                        About Us
                      </Link>
                    </div>

                    <div
                      className="shadow collapse navbar-collapse bg-white mobile-nav-dropdown"
                      id="supportDropdown"
                      aria-labelledby="supportDropdown"
                      data-parent="#accordionDrop"
                    >
                      <AnchorLink
                        className="dropdown-item header_item"
                        to="/contactus/"
                      >
                        Contact Us
                      </AnchorLink>
                      <AnchorLink
                        className="dropdown-item header_item"
                        to="/faqs/"
                      >
                        FAQs
                      </AnchorLink>
                    </div>

                    <div
                      className="shadow collapse navbar-collapse bg-white mobile-nav-dropdown"
                      id="resourcesDropdown"
                      aria-labelledby="resourcesDropdown"
                      data-parent="#accordionDrop"
                    >
                      <AnchorLink
                        className="dropdown-item first-item header_item"
                        to="/resources/videos"
                      >
                        Videos
                      </AnchorLink>
                      <AnchorLink
                        className="dropdown-item last-item"
                        to="/resources/blogs"
                      >
                        Blogs
                      </AnchorLink>
                      <AnchorLink
                        className="dropdown-item last-item"
                        to="/board/CBSE"
                      >
                        CBSE
                      </AnchorLink>
                      <AnchorLink
                        className="dropdown-item last-item"
                        to="https://leap.tatastudi.com/"
                      >
                        StudiLeap
                      </AnchorLink>
                      {/* media page link added */}
                      <AnchorLink
                        className="dropdown-item last-item"
                        to="/resources/media/"
                      >
                        Media
                      </AnchorLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}

export default Header
