import React from "react"
import { Link } from "gatsby"
import ImageHalf from "../images/logo_footer_new.jpg"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import AndroidStoreImg from "../images/playstore.png"
import AppStoreImg from "../images/appstore.png"

import LazyLoad from 'react-lazyload';

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.openNewLink = this.openNewLink.bind(this)
  }

  openNewLink = function (e) {
    e.preventDefault()
    let width = window.screen.width / 2
    let height = window.screen.height / 2
    window.open(
      e.target.getAttribute("href"),
      "name",
      "width=" + width + "," + "height=" + height
    )
  }

  render() {
    return (
      <div id="footer">
        <div className="footer_back">
          <div className="row wrapper_main">
            <div className="col-lg-2">
              <div className="footer_img_div">
                <LazyLoad>
                <img
                  src={ImageHalf}
                  alt="Tata Class Edge Logo"
                  className="footer_tce_img"
                  title="Tata Class Edge Logo"
                />
                </LazyLoad>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="footer_margin_top">
                <span className="footer_list_label">&nbsp;</span>
                <ul className="footer_list_style">
                  <li className="footer_list_item">
                    <a
                      href="https://www.tataclassedge.com/about-overview.html"
                      className="footer_list_item"
                      onClick={this.openNewLink}
                      rel="noopener"
                    >
                      Tata ClassEdge
                    </a>
                  </li>
                  <li className="footer_list_item">
                    <a
                      href="https://www.tata.com/about-us/"
                      className="footer_list_item"
                      onClick={this.openNewLink}
                      rel="noopener"
                    >
                      Tata Group
                    </a>
                  </li>
                  <li className="footer_list_item">
                    <Link className="footer_list_item" to="/privacy/">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="footer_list_item">
                    <Link
                      className="footer_list_item"
                      to="/termsandconditions/"
                    >
                      Terms &amp; Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2">
              <div className="footer_margin_top">
                <span className="footer_list_label">ABOUT STUDI</span>
                <ul className="footer_list_style">
                  <li className="footer_list_item">
                    <AnchorLink className="footer_list_item" to="/#why_studi">
                      Why Studi?
                    </AnchorLink>
                  </li>
                  <li className="footer_list_item">
                    <Link className="footer_list_item" to="/plansandpricing/">
                      Plans &amp; Pricing
                    </Link>
                  </li>
                  <li className="footer_list_item">
                    <AnchorLink
                      className="footer_list_item"
                      to="/#how_it_works"
                    >
                      How It Works
                    </AnchorLink>
                  </li>
                  <li className="footer_list_item">
                    <Link className="footer_list_item" to="/aboutus/">
                      About Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2">
              <div className="footer_margin_top">
                <span className="footer_list_label">SUPPORT</span>
                <ul className="footer_list_style">
                  <li className="footer_list_item">
                    <Link className="footer_list_item" to="/contactus">
                      Contact Us
                    </Link>
                  </li>
                  <li className="footer_list_item">
                    <Link className="footer_list_item" to="/faqs">
                      FAQs
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="footer_margin_top">
                <span className="footer_list_label footer_margin_top">
                  FOLLOW US ON
                </span>
              </div>
              <div className="social_circle">
                <a
                  className="fa fa-facebook circle_fa social_icon"
                  aria-label="facebook"
                  aria-hidden="false"
                  target="_blank"
                  href="https://www.facebook.com/tata.studi"
                  rel="noopener"
                ><span className="d-none">Facebook</span></a>
                <a
                  className="fa fa-linkedin circle social_icon"
                  aria-label="linkedin"
                  aria-hidden="false"
                  target="_blank"
                  href="https://in.linkedin.com/company/tataclassedge/"
                  rel="noopener"
                ><span className="d-none">Linkedin</span></a>
                <a
                  className="fa fa-youtube-play circle social_icon"
                  aria-label="youtube"
                  aria-hidden="false"
                  target="_blank"
                  href="https://www.youtube.com/channel/UCZPDy3ChCpxudSJJAdB8wYw/"
                  rel="noopener"
                ><span className="d-none">Youtube</span></a>

                <a
                  className="fa fa-instagram circle social_icon"
                  aria-label="insta"
                  style={{ fontWeight: "600" }}
                  aria-hidden="false"
                  target="_blank"
                  href=" https://www.instagram.com/tatastudi/"
                  rel="noopener"
                ><span className="d-none">Instagram</span></a>
              </div>
              <div className="download_buttons">
                <a
                  href="https://play.google.com/store/apps/details?id=com.tce.studi"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    src={AndroidStoreImg}
                    alt="androidlogo"
                    className="da_app_store_img_footer play_store"
                  />
                </a>
                <a
                  href="https://apps.apple.com/in/app/tata-studi/id1532854969/"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    src={AppStoreImg}
                    alt="appstorelogo"
                    className="da_app_store_img_footer"
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="address_label_border">
            <div className="row address_label_margin">
              <div className="col-lg-6">
                <span className="address_label">
                  © 2021 Tata ClassEdge. All rights reserved.
                </span>
              </div>
              <div className="col-lg-6">
                <span className="address_label_right">
                  <strong>Tata ClassEdge</strong> – A Division of Tata
                  Industries Limited Datamatics Business Solution, 3rd Floor,
                  Plot No A 16-17, Part B Cross Lane, Near MIDC police station.
                  MIDC, Andheri (East), Mumbai : 400 093.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
