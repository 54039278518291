import { Link } from "gatsby"
import React from "react"
import Logo from "../images/Tata_Studi_Open_logo.svg"
import { navigate } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import PropTypes from "prop-types"

import Blue from "../images/new-blue.svg"
import Yellow from "../images/new-yellow.svg"

class Header extends React.Component {
  componentDidMount() {
    //navbar transition

    window.onscroll = function (e) {
      if (window.innerWidth < 1024) {
        return
      }
      var nav = document.getElementById("mainNav")
      if (document.getElementById("mainNav")) {
        if (window.scrollY > 110) {
          nav.classList.add("navbar-white")
        } else {
          nav.classList.remove("navbar-white")
        }
      }
    }

    if (window.location.pathname == "/") {
      this.setState({ indexPath: true })
    } else {
      this.setState({ indexPath: false })
    }
  }

  constructor() {
    super()
    this.state = {
      isTouch: false,
      indexPath: true,
    }
    this.onTouchHandle = this.onTouchHandle.bind(this)
    this.onNavigateSupport = this.onNavigateSupport.bind(this)
  }

  onTouchHandle = e => {
    e.preventDefault()

    this.isTouch = true
  }

  onNavigateSupport = e => {
    e.preventDefault()
    if (!this.isTouch) {
      navigate("/support")
    }

    this.isTouch = false
  }

  onAboutusSupport = e => {
    e.preventDefault()
    if (!this.isTouch) {
      navigate("/aboutus/")
    }
    this.isTouch = false
  }

  onResourcesSupport = e => {
    e.preventDefault()
    // if (!this.isTouch) {
    //   navigate("/resources/videos")
    // }
    this.isTouch = false
  }

  infoPopupClose() {
    // document.getElementById("infoAlert").style.display = "none"
    document.getElementById("wrap-banner").style.marginTop = "0"
  }

  static propTypes = {
    siteTitle: PropTypes.string.isRequired,
  }

  render() {
    return (
      <div>
        <div className="fixed-top">
          {this.state.indexPath ? (
            <div
              className="alert alert-warning alert-dismissible fade show text-center"
              role="alert"
              id="infoAlert"
              style={{
                display: "block",
                marginBottom: 0,
                backgroundColor: "#fff",
              }}
            >
              <div className="color-black" style={{ fontSize: "14px" }}>
                <img src={Blue} height="12px" width="5%" className="new-img" />
                Tata Studi is available for <b>CBSE</b> 1st-10th & 11th-12th
                (Science Stream) | <b>ICSE</b> 1st-5th
                {/* with{" "}
                <span style={{ fontSize: "15px" }}>
                  <b>UP, MP & Rajasthan Boards</b>
                </span>{" "}
                for class 9th & 10th (for English medium) */}
                <img
                  src={Yellow}
                  height="12px"
                  width="5%"
                  className="new-img"
                />
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={this.infoPopupClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          ) : (
            <> </>
          )}

          <nav className="navbar navbar-expand-md navbar-yellow" id="mainNav">
            <div className="container nav_container">
              <a className="navbar-brand" href="/" id="navbar-brand">
                <img
                  src={Logo}
                  alt="Tata Studi Logo"
                  className="logo"
                  title="Tata Studi Logo"
                />{" "}
                {this.props.siteTitle}
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle header_item"
                      id="aboutstudi"
                      onMouseUp={this.onAboutusSupport}
                      onTouchEnd={this.onTouchHandle}
                    >
                      About Studi
                    </a>
                    <div
                      id="aboutstudi_custom_drowdown"
                      className="dropdown-menu"
                      aria-labelledby="aboutstudi"
                    >
                      <div className="dropdown-item arrow-item">
                        {" "}
                        <div className="arrow-up"></div>
                      </div>
                      <AnchorLink
                        className="dropdown-item first-item header_item"
                        to="/#why_studi"
                      >
                        Why Studi?
                      </AnchorLink>
                      <AnchorLink
                        className="dropdown-item last-item"
                        to="/#how_it_works"
                      >
                        How It Works
                      </AnchorLink>
                      <Link className="dropdown-item" to="/aboutus/">
                        About Us
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link header_item"
                      to="/plansandpricing/"
                    >
                      Plans & Pricing
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle header_item"
                      id="resources-nav"
                      onMouseUp={this.onResourcesSupport}
                      onTouchEnd={this.onTouchHandle}
                    >
                      Resources
                    </a>
                    <div
                      id="aboutstudi_custom_drowdown"
                      className="dropdown-menu"
                      aria-labelledby="resources-nav"
                    >
                      <div className="dropdown-item arrow-item">
                        {" "}
                        <div className="arrow-up"></div>
                      </div>
                      <AnchorLink
                        className="dropdown-item first-item header_item"
                        to="/resources/videos"
                      >
                        Videos
                      </AnchorLink>
                      <AnchorLink
                        className="dropdown-item last-item"
                        to="/resources/blogs"
                      >
                        Blogs
                      </AnchorLink>
                      <AnchorLink
                        className="dropdown-item last-item"
                        to="/board/CBSE"
                      >
                        CBSE
                      </AnchorLink>
                      <AnchorLink
                        className="dropdown-item last-item"
                        to="https://leap.tatastudi.com/"
                      >
                        StudiLeap
                      </AnchorLink>
                      {/* media page link added */}
                      <AnchorLink
                        className="dropdown-item last-item"
                        to="/resources/media/"
                      >
                        Media
                      </AnchorLink>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <AnchorLink
                      className="nav-link dropdown-toggle header_item"
                      onMouseUp={this.onNavigateSupport}
                      onTouchEnd={this.onTouchHandle}
                      id="support"
                      to="/contactus/"
                    >
                      Support
                    </AnchorLink>

                    <div
                      id="support_custom_drowdown"
                      className="dropdown-menu"
                      aria-labelledby="support"
                    >
                      <div className="dropdown-item arrow-item">
                        {" "}
                        <div className="arrow-up"></div>
                      </div>
                      <AnchorLink
                        className="dropdown-item first-item"
                        to="/contactus/"
                      >
                        Contact Us
                      </AnchorLink>
                      <AnchorLink
                        className="dropdown-item last-item"
                        to="/faqs"
                      >
                        FAQs
                      </AnchorLink>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link header_item" to="/explorestudi/">
                      Explore Studi
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link header_item" to="/studilive/">
                      Studi Live{" "}
                      <img
                        src={Yellow}
                        height="20px"
                        width="20%"
                        className="new-img"
                      />
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="home_page_buttons_cta">
                <a href="https://learn.tatastudi.com/registration.html">
                  <button
                    className="btn login_button_new trigger_popup_fricc_signup"
                    id="nav_signup_btn"
                  >
                    Sign Up
                  </button>
                </a>
                <a href="https://learn.tatastudi.com/">
                  <button className="btn signup_button_new" id="nav_login_btn">
                    Login
                  </button>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}

export default Header
