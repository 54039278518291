import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import Header from "./header"
import MobileHeader from "./mobileHeader"
import Footer from "./footer"
import MobileFooter from "./mobilefooter"
import DatalayerEvents from "../../services/datalayer"
import useIsMobile from "../hooks/useIsMobile"

const propTypes = {
  children: PropTypes.array.isRequired,
}
export default function Layout(props) {
  
  const isMobile = useIsMobile('1024px');
  const [datalayerEvents, setdatalayerEvents] = useState(new DatalayerEvents())

  useEffect(() => {
    datalayerEvents.init();
  }, [])

  return (
    <div className="container-fluid nopadding" style={{maxWidth:"100%", overflowX:"hidden"}}>
      {
        //isMobile state usage
        isMobile ? <MobileHeader /> : <Header siteTitle={""} />
      }
      <div className="hover_bkgr_fricc">
        <span className="helper"></span>
        <div>
          <p className="login_account_label"> LOGIN TO YOUR ACCOUNT</p>
          <form className="signup-form" id="cust_login_details">
            <div className="form-group">
              <input
                type="text"
                className="form-input"
                name="login_id"
                id="login_id"
                placeholder="Login Id"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-input"
                name="password"
                id="password"
                placeholder="Password"
              />
            </div>
            <div className="button-group">
              <button className="pop_cancel_button close_btn">Close</button>
              <button
                type="submit"
                form="cust_login_details"
                className="form-submit pop_login_button"
                value="submit"
              >
                Login
              </button>
            </div>
          </form>
          <p className="loginhere">
            Don't have an account ?
            <a href="#" className="loginhere-link">
              &nbsp;Signup
            </a>
          </p>
        </div>
      </div>
      <main className="wrapper_main">{props.children}</main>
      {
        //isMobile state usage
        isMobile ? <MobileFooter /> : <Footer />
      }
    </div>
  )
}
