import React from "react"
import { Link } from "gatsby"
import ImageHalf from "../images/logo_footer_new.jpg"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import AndroidStoreImg from "../images/playstore.png"
import AppStoreImg from "../images/appstore.png"

import LazyLoad from "react-lazyload"

class MobileFooter extends React.Component {
  constructor(props) {
    super(props)
    this.openNewLink = this.openNewLink.bind(this)
  }

  openNewLink = function (e) {
    e.preventDefault()
    let width = window.screen.width / 2
    let height = window.screen.height / 2
    window.open(
      e.target.getAttribute("href"),
      "name",
      "width=" + width + "," + "height=" + height
    )
  }

  render() {
    return (
      <div>
        <div className="mob_footer">
          <div className="mob_footer_inside">
            <div className="mob_footer_top">
              <div className="mob_footer_logo">
                <LazyLoad>
                  <img
                    src={ImageHalf}
                    alt="Tata Class Edge Logo"
                    title="Tata Class Edge Logo"
                  />
                </LazyLoad>
              </div>
            </div>

            <div className="footer_margin_top_bottom footer_inside_padding footer_newsletter">
              <div className="mob_footer_margin_top">
                <span className="footer_list_label">
                  SUBSCRIBE TO NEWSLETTER
                </span>
              </div>
              <div className="footer_email_btn">
                <input
                  type="text"
                  id="enter"
                  className="footer_input"
                  placeholder="Enter your email address"
                />
                <button id="clear" className="footer_go_btn">
                  &nbsp;GO&nbsp;
                </button>
              </div>
            </div>

            <div className="footer_margin_top_bottom footer_inside_padding">
              <div className="mob_footer_margin_top">
                <div className="mobile_footer_about_div">
                  <ul className="footer_list_style" id="mobilefooter">
                    <li className="mob_footer_list_item">
                      <a
                        href="https://www.tataclassedge.com/about-overview.html"
                        className="mob_footer_list_item"
                        onClick={this.openNewLink}
                      >
                        Tata ClassEdge
                      </a>
                    </li>
                    <li className="mob_footer_list_item">
                      <a
                        href="https://www.tata.com/about-us/"
                        className="mob_footer_list_item"
                        onClick={this.openNewLink}
                        rel="noopener"
                      >
                        Tata Group
                      </a>
                    </li>
                    <br />
                    <li className="mob_footer_list_item">
                      <Link className="mob_footer_list_item" to="/privacy/">
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="footer_list_item">
                      <Link
                        className="mob_footer_list_item"
                        to="/termsandconditions/"
                      >
                        Terms &amp; Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="footer_margin_top_bottom footer_inside_padding">
              <div className="mob_footer_margin_top">
                <span className="footer_list_label">ABOUT STUDI</span>
                <br />
                <div className="mobile_footer_about_div">
                  <ul className="footer_list_style" id="mobilefooter">
                    <li className="mob_footer_list_item">
                      <AnchorLink
                        className="mob_footer_list_item"
                        to="/#why_studi"
                      >
                        Why Studi?
                      </AnchorLink>
                    </li>
                    <li className="mob_footer_list_item">
                      <Link
                        className="mob_footer_list_item"
                        to="/plansandpricing/"
                      >
                        Plans &amp; Pricing
                      </Link>
                    </li>
                    <br />
                    <li className="mob_footer_list_item">
                      <AnchorLink
                        className="mob_footer_list_item"
                        to="/#how_it_works"
                      >
                        How It Works
                      </AnchorLink>
                    </li>
                    <li className="mob_footer_list_item">
                      <AnchorLink
                        className="mob_footer_list_item"
                        to="/aboutus/"
                      >
                        About Us
                      </AnchorLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="footer_margin_top_bottom footer_inside_padding">
              <div className="mob_footer_margin_top">
                <span className="footer_list_label">SUPPORT</span>
                <br />
                <div className="mobile_footer_about_div">
                  <ul className="footer_list_style" id="mobilefooter">
                    <li className="mob_footer_list_item">
                      <AnchorLink
                        className="mob_footer_list_item"
                        to="/contactus"
                      >
                        Contact Us
                      </AnchorLink>
                    </li>
                    <li className="mob_footer_list_item">
                      <AnchorLink className="mob_footer_list_item" to="/faqs">
                        FAQs
                      </AnchorLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="footer_margin_top_bottom footer_inside_padding">
              <div className="mob_footer_margin_top">
                <span className="footer_list_label footer_margin_top">
                  FOLLOW US ON
                </span>
              </div>
              <div className="social_circle">
                <a
                  className="fa fa-facebook circle_fa social_icon"
                  aria-label="facebook"
                  aria-hidden="true"
                  target="_blank"
                  href="https://www.facebook.com/tata.studi"
                  rel="noopener"
                ></a>
                <a
                  className="fa fa-linkedin circle social_icon"
                  aria-label="linkedin"
                  aria-hidden="true"
                  target="_blank"
                  href="https://in.linkedin.com/company/tataclassedge/"
                  rel="noopener"
                ></a>
                <a
                  className="fa fa-youtube-play circle social_icon"
                  aria-label="youtube"
                  aria-hidden="true"
                  target="_blank"
                  href="https://www.youtube.com/channel/UCZPDy3ChCpxudSJJAdB8wYw/"
                  rel="noopener"
                ></a>
                <a
                  className="fa fa-instagram circle social_icon"
                  aria-label="insta"
                  style={{ fontWeight: "600" }}
                  aria-hidden="true"
                  target="_blank"
                  href="https://www.instagram.com/studiwithtataclassedge/?igshid=1lxmvc7kga7i3/"
                  rel="noopener"
                ></a>
              </div>
              <div className="download_buttons_mob">
                <a
                  href="https://play.google.com/store/apps/details?id=com.tce.studi"
                  rel="noopener"
                >
                  <img
                    src={AndroidStoreImg}
                    alt="android_logo"
                    className="da_app_store_img_footer_mob play_store"
                  />
                </a>
                <a
                  href="https://apps.apple.com/in/app/tata-studi/id1532854969/"
                  rel="noopener"
                >
                  <img
                    src={AppStoreImg}
                    alt="appstore_logo"
                    className="da_app_store_img_footer_mob"
                    loading="lazy"
                  />
                </a>
              </div>
            </div>

            <div>
              <div className="address_text_size footer_inside_padding">
                <strong>Tata ClassEdge</strong>– A Division of Tata Industries
                Limited Datamatics Business Solution, 3rd Floor, Plot No A
                16-17, Part B Cross Lane, Near MIDC police station. MIDC,
                Andheri (East), Mumbai : 400 093.
              </div>
              <div className="copyright_text_align_new footer_inside_padding">
                © 2021 Tata ClassEdge. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MobileFooter
